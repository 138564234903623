import { createContext, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import Theme, { grey_3, primaryColor } from 'shared/lib/theme/Theme'
import {
    churchRepository,
    fileRepository,
    notificationRepository,
    sessionRepository,
} from '../index'
import { Church } from './Church'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Paths } from '../routing/paths'
import donkey from '../assets/images/donkey.jpg'

interface MetaTagInfo {
    name: string
    content: string
}

interface LinkInfo {
    rel: string
    href: string
}

const ChurchContext = createContext<
    | {
          isSmartBannerOpen: boolean
          applicationId?: string
          church?: Church
          getChurch(applicationId?: string): Promise<void>
          clearData: () => void
          setIsSmartBannerOpen: (isOpen: boolean) => void
      }
    | undefined
>(undefined)

export const ChurchContextProvider = ({ children }: PropsWithChildren) => {
    const location = useLocation()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()

    const [church, setChurch] = useState(churchRepository.church)
    const [isSmartBannerOpen, setIsSmartBannerOpen] = useState(false)

    const isLoadingRef = useRef(false)

    const applicationId = searchParams.get('applicationId') || churchRepository.applicationId

    const path = location.pathname.split('?')[0]
    const isSelectChurchPath = [Paths.SELECT_CHURCH, Paths.SALVATION_ARMY].includes(path as Paths)

    const updateLinksAndMetaTags = useCallback(
        (church?: Church) => {
            document.title = 'Donkey Mobile Web | toegang tot uw kerk app'

            updateMetaTags([
                { name: 'theme-color', content: isSmartBannerOpen ? grey_3 : '#ffffff' },
                {
                    name: 'msapplication-navbutton-color',
                    content: isSmartBannerOpen ? grey_3 : '#ffffff',
                },
                { name: 'apple-mobile-web-app-capable', content: 'yes' },
                {
                    name: 'apple-mobile-web-app-status-bar-style',
                    content: isSmartBannerOpen ? grey_3 : '#ffffff',
                },
                { name: 'title', content: 'Donkey Mobile Web | toegang tot uw kerk app' },
                {
                    name: 'description',
                    content:
                        'Log in op Donkey Mobile web: de toegangspoort tot uw kerk app op uw computer. Deel, communiceer en verbind in de browser met de gemak en kwaliteit die u van uw kerkapp verwacht. Samen bouwen we aan een sterke gemeenschap!',
                },
                { name: 'image', content: donkey },
            ])

            if (church && !isSelectChurchPath) {
                document.title = church.name + ' - Donkey Mobile'

                updateLinks([
                    {
                        rel: 'apple-touch-icon',
                        href: fileRepository.getImageUrl(church.image) || '',
                    },
                ])

                updateMetaTags([
                    {
                        name: 'apple-mobile-web-app-title',
                        content: church?.name ?? 'Donkey Mobile',
                    },
                    { name: 'title', content: church?.name ?? 'Donkey Mobile' },
                ])
            }
        },
        [isSmartBannerOpen, isSelectChurchPath]
    )

    const clearData = useCallback(() => {
        churchRepository.clearData({ resetApplicationId: true })
        sessionRepository.clearData()
        notificationRepository.clearData()
        setChurch(undefined)
        updateLinksAndMetaTags(undefined)
    }, [updateLinksAndMetaTags])

    const clearChurchAndNavigateToSelectChurch = useCallback(() => {
        clearData()
        navigate(Paths.SELECT_CHURCH, {
            replace: true,
        })
    }, [clearData, navigate])

    const getChurch = useCallback(
        async (appId?: string) => {
            if (isLoadingRef.current) {
                return
            }

            isLoadingRef.current = true

            churchRepository
                .getChurchByApplicationId(appId ?? applicationId)
                .then(setChurch)
                .catch(() => clearChurchAndNavigateToSelectChurch())
                .finally(() => {
                    isLoadingRef.current = false
                })
        },
        [clearChurchAndNavigateToSelectChurch, applicationId]
    )

    useEffect(() => {
        if (isSelectChurchPath) {
            updateLinksAndMetaTags(undefined)
            return
        }

        if (applicationId) {
            getChurch(applicationId)
        } else {
            clearChurchAndNavigateToSelectChurch()
        }
    }, [applicationId]) // eslint-disable-line react-hooks/exhaustive-deps

    Theme.palette.primary = Theme.palette.augmentColor({
        color: { main: church?.color ?? primaryColor },
    })

    updateLinksAndMetaTags(church)

    return (
        <ChurchContext.Provider
            value={{
                isSmartBannerOpen,
                applicationId,
                church,
                getChurch,
                clearData,
                setIsSmartBannerOpen,
            }}
        >
            {children}
        </ChurchContext.Provider>
    )
}

const updateMetaTags = (metaTags: MetaTagInfo[]) => {
    metaTags.forEach((metaTagsInfo) => {
        const existingMetaTag: HTMLMetaElement | null = document.querySelector(
            `meta[name="${metaTagsInfo.name}"]`
        )

        if (existingMetaTag) {
            existingMetaTag.content = metaTagsInfo.content
        } else {
            const metaTag = document.createElement('meta')
            metaTag.name = metaTagsInfo.name
            metaTag.content = metaTagsInfo.content
            document.head.appendChild(metaTag)
        }
    })
}

const updateLinks = (links: LinkInfo[]) => {
    links.forEach((linkInfo) => {
        const existingLink: HTMLLinkElement | null = document.querySelector(
            `link[rel="${linkInfo.rel}"]`
        )

        if (existingLink) {
            existingLink.href = linkInfo.href
        } else {
            const link = document.createElement('link')
            link.rel = linkInfo.rel
            link.href = linkInfo.href
            document.head.appendChild(link)
        }
    })
}

export default ChurchContext
