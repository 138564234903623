import { Box, Typography } from '@mui/material'
import { useTranslation } from '../i18n'

interface Properties {
    message?: string
}

export const TooltipTitleView = (properties: Properties) => {
    const translation = useTranslation()

    return (
        <>
            <Typography
                variant="body1"
                sx={{
                    fontWeight: 600,
                }}
            >
                {translation('editing_not_possible')}
            </Typography>
            <Box mt={1} />
            {properties.message && (
                <Typography sx={{ lineHeight: 1 }} display="inline" variant="body2">
                    {properties.message}
                </Typography>
            )}
        </>
    )
}
