import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useContext, useState } from 'react'
import { ErrorHandler } from 'shared/lib/components/ErrorHandler'
import { PopupModal } from 'shared/lib/components/PopupModal'
import { PrimaryContainedButton } from 'shared/lib/components/buttons/ContainedButtons'
import { DatePicker } from 'shared/lib/components/textfields/DatePicker'
import { TextField } from 'shared/lib/components/textfields/TextField'
import { LocalImage } from 'shared/lib/forms/LocalImage'
import { useTranslation } from 'shared/lib/i18n'
import { ImageCropper } from 'shared/lib/images/ImageCropper'
import { Address } from 'shared/lib/models/Address'
import { parsePhoneNumber } from 'shared/lib/utils/StringUtils'
import SessionContext from '../authentication/SessionContextProvider'
import { DropProfileImageZone } from '../authentication/registration/DropProfileImageZone'
import { SwitchRow } from 'shared/lib/components/switch/SwitchRow'
import i18n from '../i18n'

export const UserEditProfileView = () => {
    const translations = useTranslation()

    const { getSignedInUser, updateSignedInUser } = useContext(SessionContext)!
    const signedInUser = getSignedInUser()!

    const [functions, setFunctions] = useState(
        (signedInUser.functions.length <= 2
            ? [...signedInUser.functions, []]
            : signedInUser.functions) as string[]
    )
    const [isEmailAddressHidden, setIsEmailAddressHidden] = useState(
        signedInUser?.isEmailAddressHidden
    )
    const [firstName, setFirstName] = useState(signedInUser.firstName || '')
    const [lastName, setLastName] = useState(signedInUser.lastName || '')
    const [phoneNumber, setPhoneNumber] = useState(signedInUser?.phoneNumber || '')
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState(
        signedInUser?.mobilePhoneNumber || ''
    )
    const [birthday, setBirthday] = useState<DateTime | undefined>(signedInUser?.birthday)
    const [zipCode, setZipCode] = useState(signedInUser?.address?.zipCode || '')
    const [city, setCity] = useState(signedInUser?.address?.city || '')
    const [street, setStreet] = useState(signedInUser?.address?.street || '')
    const [about, setAbout] = useState(signedInUser?.about || '')
    const [error, setError] = useState<Error | undefined>()
    const [birthdayErrorMessage, setBirthdayErrorMessage] = useState<string | undefined>(undefined)
    const [selectedImage, setSelectedImage] = useState<LocalImage | undefined>()
    const [popupText, setPopupText] = useState<string | undefined>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [newProfileImageFile, setNewProfileImageFile] = useState<LocalImage | undefined>()

    const isFirstNameValid = firstName.trim().length > 0
    const isLastNameValid = lastName.trim().length > 0
    const isBirthdayValid =
        birthday === undefined ||
        (birthday.isValid &&
            birthday <= DateTime.now() &&
            birthday >= DateTime.fromISO('1900-01-01'))
    const isStreetValid = !((street === '' && zipCode !== '') || (street === '' && city !== ''))
    const isZipCodeValid = !((zipCode === '' && street !== '') || (zipCode === '' && city !== ''))
    const isCityValid = !((city === '' && street !== '') || (city === '' && zipCode !== ''))

    const canSave =
        isFirstNameValid &&
        isLastNameValid &&
        isBirthdayValid &&
        isStreetValid &&
        isZipCodeValid &&
        isCityValid

    const onFunctionChanged = (index: number, value: string) => {
        const updatedFunctions = [...functions]
        updatedFunctions[index] = value

        if (value.trim() === '' && index < 2) {
            updatedFunctions.splice(index, 1)
        }
        if (updatedFunctions.length < 3 && updatedFunctions[updatedFunctions.length - 1] !== '') {
            updatedFunctions.push('')
        }

        setFunctions(updatedFunctions)
    }

    const onEmailVisibilityChanged = (value: boolean) => {
        setIsEmailAddressHidden(value)
    }

    const handleMobilePhoneNumberChange = (newValue: string) => {
        setMobilePhoneNumber(parsePhoneNumber(newValue))
    }

    const handlePhoneNumberChange = (newValue: string) => {
        setPhoneNumber(parsePhoneNumber(newValue))
    }

    const onBirthdayChanged = (date: DateTime | undefined) => {
        const isValidDate = date === undefined || date.isValid
        setBirthdayErrorMessage(isValidDate ? undefined : translations('invalid_date'))
        setBirthday(date)
    }

    const onImageCropped = (imageFile: LocalImage, thumbnailFile: LocalImage) => {
        setNewProfileImageFile(imageFile)
        setSelectedImage(undefined)
        onSave({ imageFile, thumbnailFile })
    }

    const onImageChanged = (image: LocalImage) => {
        setSelectedImage(image)
    }

    const onSave = (params?: { imageFile: LocalImage; thumbnailFile: LocalImage }) => {
        setIsLoading(true)

        let address: Address | null = null
        if (street !== '' && zipCode !== '' && city !== '') {
            address = { street, zipCode, city }
        }

        updateSignedInUser({
            firstName,
            lastName,
            fullSizeImage: params?.imageFile,
            thumbnailImage: params?.thumbnailFile,
            phoneNumber,
            mobilePhoneNumber,
            birthday,
            address,
            functions,
            about,
            isEmailAddressHidden,
        })
            .then(() => setPopupText(translations('save_changes_succeeded_message')))
            .catch(setError)
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <Box display="flex" gap={3} flexDirection={'column'}>
                <DropProfileImageZone
                    id="UserEditProfileView"
                    image={signedInUser?.image}
                    imageFile={newProfileImageFile}
                    onImageChanged={(image) => onImageChanged(image)}
                />
                {selectedImage && (
                    <ImageCropper
                        image={selectedImage.file}
                        onCancelCropClicked={() => setSelectedImage(undefined)}
                        onImageCropped={onImageCropped}
                        translations={translations}
                    />
                )}

                <TextField
                    error={!isFirstNameValid}
                    fullWidth={true}
                    autoFocus={false}
                    value={firstName}
                    maxLength={75}
                    label={translations('first_name')}
                    onChanged={setFirstName}
                    helperText={!isFirstNameValid ? translations('empty_first_name') : ''}
                />

                <TextField
                    error={!isLastNameValid}
                    fullWidth={true}
                    autoFocus={false}
                    value={lastName}
                    maxLength={75}
                    label={translations('last_name')}
                    onChanged={setLastName}
                    helperText={!isLastNameValid ? translations('empty_last_name') : ''}
                />

                <TextField
                    fullWidth={true}
                    autoFocus={false}
                    maxLength={320}
                    value={about}
                    label={translations('about_me')}
                    onChanged={setAbout}
                />
                {functions.map((func, index) => (
                    <TextField
                        key={index}
                        fullWidth={true}
                        autoFocus={false}
                        maxLength={50}
                        value={func}
                        label={translations('function') + ` ${index + 1}`}
                        onChanged={(value: string) => onFunctionChanged(index, value)}
                    />
                ))}
                <TextField
                    fullWidth={true}
                    autoFocus={false}
                    value={
                        isEmailAddressHidden ? translations('hidden') : signedInUser?.emailAddress
                    }
                    label={translations('email_address')}
                    disabled={true}
                />
                <SwitchRow
                    title={translations('hide_email_text')}
                    isChecked={isEmailAddressHidden}
                    onChanged={onEmailVisibilityChanged}
                />
                <Box display={'flex'} width={'60%'} gap={3} flexDirection={'column'}>
                    <TextField
                        fullWidth={true}
                        autoFocus={false}
                        maxLength={50}
                        value={mobilePhoneNumber}
                        label={translations('mobile_number')}
                        onChanged={handleMobilePhoneNumberChange}
                    />

                    <TextField
                        fullWidth={true}
                        autoFocus={false}
                        maxLength={50}
                        value={phoneNumber}
                        label={translations('phone_number')}
                        onChanged={handlePhoneNumberChange}
                    />

                    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={i18n.language}>
                        <DatePicker
                            disableFuture={true}
                            value={birthday || null}
                            label={translations('birthday')}
                            helperText={birthdayErrorMessage}
                            onChange={(date) => onBirthdayChanged(date || undefined)}
                        />
                    </LocalizationProvider>
                </Box>
                <TextField
                    error={!isStreetValid}
                    fullWidth={true}
                    autoFocus={false}
                    maxLength={255}
                    value={street}
                    label={translations('street_and_house_number')}
                    onChanged={setStreet}
                    helperText={!isStreetValid ? translations('empty_address') : ''}
                />
                <Box display={'flex'} justifyContent={'center'} flexDirection={'row'} gap={3}>
                    <TextField
                        error={!isZipCodeValid}
                        fullWidth={true}
                        autoFocus={false}
                        maxLength={15}
                        value={zipCode}
                        label={translations('zip_code')}
                        onChanged={setZipCode}
                        helperText={!isZipCodeValid ? translations('empty_zip_code') : ''}
                    />

                    <TextField
                        error={!isCityValid}
                        fullWidth={true}
                        autoFocus={false}
                        maxLength={100}
                        value={city}
                        label={translations('city')}
                        onChanged={setCity}
                        helperText={!isCityValid ? translations('empty_city') : ''}
                    />
                </Box>

                <Box>
                    <PrimaryContainedButton
                        fullWidth={false}
                        title={translations('save')}
                        onClick={() => onSave()}
                        disabled={!canSave || isLoading}
                        isLoading={isLoading}
                    />
                </Box>
            </Box>

            <PopupModal
                message={popupText}
                horizontal="center"
                onClose={() => setPopupText(undefined)}
            />

            <ErrorHandler error={error} horizontal="left" translations={translations} />
        </>
    )
}
