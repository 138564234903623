import { Box, Tooltip, Typography } from '@mui/material'
import { TooltipTitleView } from '../TooltipTitleView'
import { IOSSwitch } from './IOSSwitch'
import { grey_1 } from '../../theme/Theme'

interface Properties {
    testId?: string
    header?: string
    title: string
    titleNote?: string
    description?: string
    disabledTooltipMessage?: string
    isChecked: boolean
    isDisabled?: boolean
    mt?: number

    onChanged(value: boolean): void
}

export const SwitchRow = ({
    title,
    titleNote,
    description,
    header,
    mt,
    ...properties
}: Properties) => {
    const isDisabled = properties.isDisabled ?? false

    return (
        <Box
            data-testid={properties.testId}
            key={title}
            pb={1}
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ mt: mt !== undefined ? `${mt}px` : '24px' }}
        >
            <Box mr={6} flexGrow={1}>
                {header && (
                    <Box mb={2}>
                        <Typography variant="h3">{header}</Typography>
                    </Box>
                )}
                <Typography variant="body1">
                    {title}
                    {titleNote && <span style={{ color: grey_1 }}>{titleNote}</span>}
                </Typography>
                {description && (
                    <Box mt={0.5}>
                        <Typography variant="body2">{description}</Typography>
                    </Box>
                )}
            </Box>
            <Tooltip
                placement="top"
                arrow={true}
                title={
                    !isDisabled || properties.disabledTooltipMessage === undefined ? (
                        ''
                    ) : (
                        <TooltipTitleView message={properties.disabledTooltipMessage} />
                    )
                }
            >
                <Box>
                    <IOSSwitch
                        checked={properties.isChecked}
                        disabled={isDisabled}
                        onChange={(event: any) => properties.onChanged(event.target.checked)}
                    />
                </Box>
            </Tooltip>
        </Box>
    )
}
